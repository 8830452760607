import { Trans } from '@lingui/macro'
import { useTrace } from '@uniswap/analytics'
import { InterfaceSectionName, NavBarSearchTypes } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import Badge from 'components/Badge'
import { SupportedChainId } from 'constants/chains'
import { SearchToken } from 'graphql/data/SearchTokens'
import { useFetchedTokenDatas } from 'graphql/thegraph/tokens/tokenData'
import useTrendingTokens from 'graphql/thegraph/tokens/trendingTokens'
import useSearchTokenFromSubGraph from 'hooks/useSearchTokensFromSubgraph'
import { Box } from 'nft/components/Box'
import { Column, Row } from 'nft/components/Flex'
import { subheadSmall } from 'nft/css/common.css'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import BnbLogoURI from '../../assets/svg/bnb-logo.svg'
import { ClockIcon, TrendingArrow } from '../../nft/components/icons'
import { useRecentlySearchedAssets } from './RecentlySearchedAssets'
import * as styles from './SearchBar.css'
import { SkeletonRow, TokenRow } from './SuggestionRow'

interface SearchBarDropdownSectionProps {
  toggleOpen: () => void
  suggestions: any[]
  header: JSX.Element
  headerIcon?: JSX.Element
  hoveredIndex: number | undefined
  startingIndex: number
  setHoveredIndex: (index: number | undefined) => void
  isLoading?: boolean
  eventProperties: Record<string, unknown>
}

const SearchBarDropdownSection = ({
  toggleOpen,
  suggestions,
  header,
  headerIcon = undefined,
  hoveredIndex,
  startingIndex,
  setHoveredIndex,
  isLoading,
  eventProperties,
}: SearchBarDropdownSectionProps) => {
  return (
    <Column gap="12" data-cy="searchbar-dropdown">
      <Row paddingX="16" paddingY="4" gap="8" color="gray300" className={subheadSmall} style={{ lineHeight: '20px' }}>
        {headerIcon ? headerIcon : null}
        <Box>{header}</Box>
      </Row>
      <Column gap="12">
        {suggestions.map((suggestion, index) =>
          isLoading || !suggestion ? (
            <SkeletonRow key={index} />
          ) : (
            <TokenRow
              key={index}
              token={suggestion}
              isHovered={hoveredIndex === index + startingIndex}
              setHoveredIndex={setHoveredIndex}
              toggleOpen={toggleOpen}
              index={index + startingIndex}
              eventProperties={{
                position: index + startingIndex,
                selected_search_result_name: suggestion.name,
                selected_search_result_address: suggestion.address,
                ...eventProperties,
              }}
            />
          )
        )}
      </Column>
    </Column>
  )
}

const BNBLogo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`
const BNBComingSoonBadge = styled(Badge)`
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundModule};
  color: ${({ theme }) => theme.textSecondary};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  opacity: 1;
  padding: 8px;
  margin: 16px 16px 4px;
  width: calc(100% - 32px);
`

interface SearchBarDropdownProps {
  toggleOpen: () => void
  tokens: SearchToken[] | any
  queryText: string
  hasInput: boolean
  isLoading: boolean
}

export const SearchBarDropdown = ({ toggleOpen, tokens, queryText, hasInput, isLoading }: SearchBarDropdownProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(0)

  const { data: searchHistory } = useRecentlySearchedAssets()

  const shortenedHistory = useMemo(() => searchHistory?.slice(0, 2) ?? [...Array<SearchToken>(2)], [searchHistory])

  const { pathname } = useLocation()
  const { chainId } = useWeb3React()
  const isTokenPage = pathname.includes('/tokens')
  const [resultsState, setResultsState] = useState<ReactNode>()

  const { data: trendingTokenDatas } = useTrendingTokens()
  const idTokenTrending: any = trendingTokenDatas?.map((trendingTokenData) => trendingTokenData.id)

  const { data: tokenDatas } = useFetchedTokenDatas(idTokenTrending ?? [])

  const convertData = useSearchTokenFromSubGraph(Object.values(tokenDatas ?? []), chainId)

  const trendingTokensLength = isTokenPage ? 3 : 2
  const trendingTokens = useMemo(
    () => (convertData || [])?.slice(0, trendingTokensLength) ?? [...Array<SearchToken>(trendingTokensLength)],
    [convertData, trendingTokensLength]
  )

  const totalSuggestions = hasInput
    ? tokens.length
    : Math.min(shortenedHistory.length, 2) + (isTokenPage ? trendingTokens?.length ?? 0 : 0)

  // Navigate search results via arrow keys
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (!hoveredIndex) {
          setHoveredIndex(totalSuggestions - 1)
        } else {
          setHoveredIndex(hoveredIndex - 1)
        }
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        if (hoveredIndex && hoveredIndex === totalSuggestions - 1) {
          setHoveredIndex(0)
        } else {
          setHoveredIndex((hoveredIndex ?? -1) + 1)
        }
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [toggleOpen, hoveredIndex, totalSuggestions])

  const trace = JSON.stringify(useTrace({ section: InterfaceSectionName.NAVBAR_SEARCH }))
  useEffect(() => {
    const eventProperties = {
      total_suggestions: totalSuggestions,
      query_text: queryText,
      ...JSON.parse(trace),
    }
    if (!isLoading) {
      const tokenSearchResults =
        tokens.length > 0 ? (
          <SearchBarDropdownSection
            hoveredIndex={hoveredIndex}
            startingIndex={0}
            setHoveredIndex={setHoveredIndex}
            toggleOpen={toggleOpen}
            suggestions={tokens}
            eventProperties={{
              suggestion_type: NavBarSearchTypes.TOKEN_SUGGESTION,
              ...eventProperties,
            }}
            header={<Trans>Tokens</Trans>}
            isLoading={tokens.length === 0 ? true : false}
          />
        ) : !isLoading ? (
          <></>
        ) : (
          <Box className={styles.notFoundContainer}>
            <Trans>No tokens found.</Trans>
          </Box>
        )

      const currentState = () =>
        hasInput ? (
          // Empty or Up to 8 combined tokens and nfts
          <Column gap="20">{tokenSearchResults}</Column>
        ) : (
          // Recent Searches, Trending Tokens, Trending Collections
          <Column gap="20">
            <SearchBarDropdownSection
              hoveredIndex={hoveredIndex}
              startingIndex={0}
              setHoveredIndex={setHoveredIndex}
              toggleOpen={toggleOpen}
              suggestions={shortenedHistory}
              eventProperties={{
                suggestion_type: NavBarSearchTypes.RECENT_SEARCH,
                ...eventProperties,
              }}
              header={<Trans>Recent searches</Trans>}
              headerIcon={<ClockIcon />}
              isLoading={shortenedHistory.length === 0 ? true : false}
            />

            <SearchBarDropdownSection
              hoveredIndex={hoveredIndex}
              startingIndex={shortenedHistory.length}
              setHoveredIndex={setHoveredIndex}
              toggleOpen={toggleOpen}
              suggestions={convertData}
              eventProperties={{
                suggestion_type: NavBarSearchTypes.TOKEN_TRENDING,
                ...eventProperties,
              }}
              header={<Trans>Popular tokens</Trans>}
              headerIcon={<TrendingArrow />}
              isLoading={convertData.length === 0 ? true : false}
            />
          </Column>
        )

      setResultsState(currentState)
    }
  }, [
    isLoading,
    tokens,
    // trendingCollections,
    // trendingCollectionsAreLoading,
    // trendingTokens,
    // trendingTokenDatas,
    hoveredIndex,
    toggleOpen,
    tokenDatas,
    // shortenedHistory,
    hasInput,
    // isTokenPage,
    queryText,
    totalSuggestions,
    // trace,
    // searchHistory,
  ])

  const showBNBComingSoonBadge = chainId === SupportedChainId.BNB && !isLoading

  return (
    <Box className={styles.searchBarDropdownNft}>
      <Box opacity={isLoading ? '0.3' : '1'} transition="125">
        {resultsState}
        {showBNBComingSoonBadge && (
          <BNBComingSoonBadge>
            <BNBLogo src={BnbLogoURI} />
            <ThemedText.BodySmall color="textSecondary" fontSize="14px" fontWeight="400" lineHeight="20px">
              <Trans>Coming soon: search and explore tokens on BNB Chain</Trans>
            </ThemedText.BodySmall>
          </BNBComingSoonBadge>
        )}
      </Box>
    </Box>
  )
}
