import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import AccountDropdown, { useAccountDrawer } from 'components/AccountDrawer'
import PrefetchBalancesWrapper from 'components/AccountDrawer/PrefetchBalancesWrapper'
import Loader from 'components/Icons/LoadingSpinner'
import { IconWrapper } from 'components/Identicon/StatusIcon'
import { getConnection } from 'connection'
import { navSearchInputVisibleSize } from 'hooks/useScreenSize'
import { Portal } from 'nft/components/common/Portal'
import { useIsNftClaimAvailable } from 'nft/hooks/useIsNftClaimAvailable'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { flexRowNoWrap } from 'theme/styles'
import { shortenAddress } from 'utils'

import useLast from '../../hooks/useLast'
import { useAppSelector } from '../../state/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/types'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import { RowBetween } from '../Row'

// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 9999

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${flexRowNoWrap};
  width: 100%;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  height: 40px;
  background: ${({ theme }) => (theme.darkMode ? 'accentActiveSoft' : 'white')};
  border: 1px solid ${({ theme }) => (theme.darkMode ? 'transparent' : '#D2D9EE')};
  cursor: pointer;
  user-select: none;
  margin-right: 2px;
  margin-left: 2px;
`

const Web3StatusConnectWrapper = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(90deg, #534fc7 0%, #ac10ac 100%);
  color: ${({ theme }) => theme.textPrimary};

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{
  pending?: boolean
  isClaimAvailable?: boolean
}>`
  background: ${({ theme }) => (theme.darkMode ? theme.backgroundSearchBar : 'rgba(255, 255, 255, 0.3)')};
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 500;
  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => (theme.darkMode ? '#293249' : '#D2D9EE')};
    box-shadow: none;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: ${({ pending }) => !pending && '40px'};

    ${IconWrapper} {
      margin-right: 0;
    }
  }
`

const AddressAndChevronContainer = styled.div`
  display: flex;

  @media only screen and (max-width: ${navSearchInputVisibleSize}px) {
    display: none;
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

const StyledConnectButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 9px 18px;
  color: white;
`

const WrapperWalletAndInfo = styled.div`
  display: flex;
  justify-content: center;
`

function Web3StatusInner() {
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const ignoreWhileSwitchingChain = useCallback(() => !switchingChain, [switchingChain])
  const { account, connector, ENSName } = useLast(useWeb3React(), ignoreWhileSwitchingChain)
  const connection = getConnection(connector)

  const [, toggleWalletDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
    toggleWalletDrawer()
  }, [toggleWalletDrawer])
  const isClaimAvailable = useIsNftClaimAvailable((state) => state.isClaimAvailable)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length

  if (account) {
    return (
      <WrapperWalletAndInfo>
        <Web3StatusConnected
          disabled={Boolean(switchingChain)}
          data-testid="web3-status-connected"
          onClick={handleWalletDropdownClick}
          pending={hasPendingTransactions}
          isClaimAvailable={isClaimAvailable}
        >
          {!hasPendingTransactions && (
            <StatusIcon size={24} account={account} connection={connection} showMiniIcons={false} />
          )}
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>
                <Trans>{pending?.length} Pending</Trans>
              </Text>{' '}
              <Loader stroke="#5F60D4" />
            </RowBetween>
          ) : (
            <AddressAndChevronContainer>
              <Text>{ENSName || shortenAddress(account)}</Text>
            </AddressAndChevronContainer>
          )}
        </Web3StatusConnected>
        {/* <MenuDropdown /> */}
      </WrapperWalletAndInfo>
    )
  } else {
    return (
      <WrapperWalletAndInfo>
        <Web3StatusConnectWrapper
          tabIndex={0}
          onKeyPress={(e) => e.key === 'Enter' && handleWalletDropdownClick()}
          onClick={handleWalletDropdownClick}
        >
          <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet">
            <Trans>Connect</Trans>
          </StyledConnectButton>
        </Web3StatusConnectWrapper>
        {/* <MenuDropdown /> */}
      </WrapperWalletAndInfo>
    )
  }
}

// eslint-disable-next-line import/no-unused-modules
export default function Web3Status() {
  return (
    <PrefetchBalancesWrapper>
      <Web3StatusInner />
      <Portal>
        <AccountDropdown />
      </Portal>
    </PrefetchBalancesWrapper>
  )
}
