import gql from 'graphql-tag'
import { useTokenDayDatasQuery } from 'graphql/thegraph/__generated__/types-and-hooks'
import { useTheGraphClient } from 'hooks/useTheGraphClient'
import { useMemo } from 'react'

gql`
  query tokenDayDatas($date: Int) {
    tokenDayDatas(
      first: 100
      orderBy: totalValueLockedUSD
      orderDirection: desc
      subgraphError: allow
      where: { date_gt: $date }
    ) {
      id
      date
    }
  }
`

/**
 * Fetch top addresses by volume
 */
export function useTokenDayDatas(): {
  loading: boolean
  error: boolean
  addresses: string[] | undefined
} {
  const currentDate = Math.round(Date.now() / 86400 / 1000) * 86400 - 86500

  const client = useTheGraphClient()

  const { data, loading, error } = useTokenDayDatasQuery({
    variables: {
      date: currentDate,
    },
    fetchPolicy: 'network-only',
    client,
  })

  const formattedData = useMemo(() => {
    if (data) {
      return data.tokenDayDatas.map((t) => t.id.split('-')[0])
    } else {
      return undefined
    }
  }, [data])

  return {
    loading: loading,
    error: Boolean(error),
    addresses: formattedData,
  }
}
