import { t } from '@lingui/macro'
import { Box } from 'nft/components/Box'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components/macro'

import * as styles from './NavIcon.css'
import { useScreenSize } from 'hooks/useScreenSize'

interface NavIconProps {
  children: ReactNode
  isActive?: boolean
  label?: string
  onClick: () => void
  activeBackground?: boolean
}

export const NavIcon = ({
  children,
  isActive,
  label = t`Navigation button`,
  onClick,
  activeBackground,
}: NavIconProps) => {
  const theme = useTheme()
  const isScreenSize = useScreenSize()
  const isMobile = !isScreenSize['md']
  return (
    <Box
      as="button"
      className={styles.navIcon}
      color={isActive ? 'textPrimary' : 'textSecondary'}
      onClick={onClick}
      height="40"
      width="40"
      aria-label={label}
      background={isMobile ? 'transparent' : theme.darkMode ? 'transparent' : 'white'}
      style={{border: isMobile ? 'none' : theme.darkMode ? '1px solid #293249' : '1px solid #D2D9EE'}}
    >
      {children}
    </Box>
  )
}
