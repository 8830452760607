import { Web3ReactHooks } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { SupportedChainId } from 'constants/chains'

import BRAVE_ICON from '../assets/wallets/brave-icon.svg'
import LEDGER_ICON from '../assets/wallets/ledger-icon.svg'
import RABBY_ICON from '../assets/wallets/rabby-icon.svg'
import TRUST_WALLET_ICON from '../assets/wallets/trustwallet-icon.svg'
import XSWAP_WALLET_ICON from '../assets/wallets/xswap-wallet-icon.svg'

export enum ConnectionType {
  UNISWAP_WALLET = 'UNISWAP_WALLET',
  INJECTED = 'INJECTED',
  COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  NETWORK = 'NETWORK',
  GNOSIS_SAFE = 'GNOSIS_SAFE',
}

export interface Connection {
  getName(): string
  connector: Connector
  hooks: Web3ReactHooks
  type: ConnectionType
  getIcon?(isDarkMode: boolean): string
  shouldDisplay(): boolean
  overrideActivate?: (chainId?: SupportedChainId) => boolean
  isNew?: boolean
}

export const InjectedWalletTable: { [key in keyof NonNullable<Window['ethereum']>]?: { name: string; icon: string } } =
  {
    isBraveWallet: { name: 'Brave', icon: BRAVE_ICON },
    isRabby: { name: 'Rabby', icon: RABBY_ICON },
    isXSwap: { name: 'XSwap Wallet', icon: XSWAP_WALLET_ICON },
    isTrust: { name: 'Trust Wallet', icon: TRUST_WALLET_ICON },
    isLedgerConnect: { name: 'Ledger', icon: LEDGER_ICON },
  }
