import { updateTokenData, addTokenKeys, updateChartData, updatePriceData, updateTokenLoading } from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { PriceChartEntry, TokenChartEntry, TokenData } from './types'
import { currentTimestamp } from 'utils'
import { SupportedChainId } from 'constants/chains'

export interface TokensState {
  // analytics data from
  loading: boolean
  byAddress: {
    [networkId: string]: {
      [address: string]: {
        data: TokenData | undefined
        chartData: TokenChartEntry[] | undefined
        priceData: {
          oldestFetchedTimestamp?: number | undefined
          [secondsInterval: number]: PriceChartEntry[] | undefined
        }
        lastUpdated: number | undefined
      }
    }
  }
}

export const initialState: TokensState = {
  loading: false,
  byAddress: {
    [SupportedChainId.XDC]: {},
    [SupportedChainId.XDC_TESTNET]: {},
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateTokenData, (state, { payload: { tokens, networkId } }) => {
      tokens.map(
        (tokenData) =>
          (state.byAddress[networkId][tokenData.address] = {
            ...state.byAddress[networkId][tokenData.address],
            data: tokenData,
            lastUpdated: currentTimestamp(),
          })
      )
    }) // add address to byAddress keys if not included yet
    .addCase(addTokenKeys, (state, { payload: { tokenAddresses, networkId } }) => {
      tokenAddresses.map((address) => {
        if (state.byAddress[networkId] && !state.byAddress[networkId][address]) {
          state.byAddress[networkId][address] = {
            data: undefined,
            chartData: undefined,
            priceData: {},
            lastUpdated: undefined,
          }
        }
      })
    })
    // add list of pools the token is included in
    .addCase(updateChartData, (state, { payload: { tokenAddress, chartData, networkId } }) => {
      state.byAddress[networkId][tokenAddress] = { ...state.byAddress[networkId][tokenAddress], chartData }
    })
    // update historical price volume based on interval size
    .addCase(
      updatePriceData,
      (state, { payload: { tokenAddress, secondsInterval, priceData, oldestFetchedTimestamp, networkId } }) => {
        state.byAddress[networkId][tokenAddress] = {
          ...state.byAddress[networkId][tokenAddress],
          priceData: {
            ...state.byAddress[networkId][tokenAddress].priceData,
            [secondsInterval]: priceData,
            oldestFetchedTimestamp,
          },
        }
      }
    )
    // update loading state
    .addCase(updateTokenLoading, (state, { payload: { loading } }) => {
      state.loading = loading
    })
)
