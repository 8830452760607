import { Currency } from '@x-swap-protocol/sdk-core'
import { SupportedChainId } from 'constants/chains'

export function currencyId(currency: Currency): string {
  if (currency.isNative) {
    if (currency.chainId === SupportedChainId.XDC || currency.chainId === SupportedChainId.XDC_TESTNET) {
      return 'XDC'
    } else {
      return 'ETH'
    }
  }
  if (currency.isToken) return currency.address
  throw new Error('invalid currency')
}
