import gql from 'graphql-tag';
import { apolloClient } from 'graphql/thegraph/apollo';
import { useSearchTokenIdsQuery } from 'graphql/thegraph/__generated__/types-and-hooks';
import { useMemo } from 'react';

gql`
  query searchTokenIds($name: String!) {
    tokens(
      first: 2
      where: { or: [{ name_contains_nocase: $name }, { symbol_contains_nocase: $name }] }
    ) {
      id
    }
  }
`;

export function useSearchTokenIds(name: string): {
  loading: boolean;
  error: boolean;
  addresses: string[] | undefined;
} {
  const { data, loading, error } = useSearchTokenIdsQuery({
    variables: {
      name: name,
    },
    skip: !name,
    fetchPolicy: 'network-only',
    client: apolloClient,
  });

  const formattedData = useMemo(() => {
    if (data) {
      return data.tokens.map((t) => t.id.split('-')[0]);
    } else {
      return undefined;
    }
  }, [data]);

  return {
    loading: loading,
    error: Boolean(error),
    addresses: formattedData,
  };
}
