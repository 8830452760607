import { createAction } from '@reduxjs/toolkit';
import { SupportedChainId } from 'constants/chains';
import { PriceChartEntry, TokenChartEntry, TokenData } from './types';

// protocol wide info
export const updateTokenData = createAction<{ tokens: TokenData[]; networkId: SupportedChainId }>(
  'tokens/updateTokenData'
)

// add token address to byAddress
export const addTokenKeys = createAction<{ tokenAddresses: string[]; networkId: SupportedChainId }>(
  'tokens/addTokenKeys'
)

// tvl and volume data over time
export const updateChartData = createAction<{
  tokenAddress: string
  chartData: TokenChartEntry[]
  networkId: SupportedChainId
}>('tokens/updateChartData')

// price data at arbitrary intervals
export const updatePriceData = createAction<{
  tokenAddress: string
  secondsInterval: number
  priceData: PriceChartEntry[] | undefined
  oldestFetchedTimestamp: number
  networkId: SupportedChainId
}>('tokens/updatePriceData')

// add token address to byAddress
export const updateTokenLoading = createAction<{ loading: boolean }>(
  'tokens/updateTokenLoading'
)