import { useWeb3React } from '@web3-react/core'
import { formatNumber, NumberType } from '@x-swap-protocol/conedison/format'
import Row from 'components/Row'
import { formatDelta } from 'components/Tokens/TokenDetails/PriceChart'
import { SupportedChainId } from 'constants/chains'
import { Chain, PortfolioBalancesQuery } from 'graphql/data/__generated__/types-and-hooks'
import { getTokenDetailsURL, gqlToCurrency } from 'graphql/data/util'
import usePortfoliosListTokens from 'hooks/usePortfoliosListTokens'
import { useAtomValue } from 'jotai/utils'
import { EmptyWalletModule } from 'nft/components/profile/view/EmptyWalletContent'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { EllipsisStyle, ThemedText } from 'theme'
import { getBalanceAmount } from 'utils/formatCurrencyAmount'

import { useToggleAccountDrawer } from '../..'
import { PortfolioArrow } from '../../AuthenticatedHeader'
import { hideSmallBalancesAtom } from '../../SmallBalanceToggle'
import { ExpandoRow } from '../ExpandoRow'
import { PortfolioLogo } from '../PortfolioLogo'
import PortfolioRow, { PortfolioSkeleton, PortfolioTabWrapper } from '../PortfolioRow'

const HIDE_SMALL_USD_BALANCES_THRESHOLD = 1

function meetsThreshold(tokenBalance: TokenBalance, hideSmallBalances: boolean) {
  return !hideSmallBalances || (tokenBalance.denominatedValue?.value ?? 0) > HIDE_SMALL_USD_BALANCES_THRESHOLD
}

export default function Tokens({ account }: { account: string }) {
  const toggleWalletDrawer = useToggleAccountDrawer()
  const hideSmallBalances = useAtomValue(hideSmallBalancesAtom)
  const [showHiddenTokens, setShowHiddenTokens] = useState(false)
  const { tokenBalances, isLoading } = usePortfoliosListTokens(account)

  const visibleTokens = useMemo(() => {
    return !hideSmallBalances
      ? tokenBalances ?? []
      : tokenBalances?.filter((tokenBalance) => meetsThreshold(tokenBalance, hideSmallBalances)) ?? []
  }, [tokenBalances, hideSmallBalances])

  const hiddenTokens = useMemo(() => {
    return !hideSmallBalances
      ? []
      : tokenBalances?.filter((tokenBalance) => !meetsThreshold(tokenBalance, hideSmallBalances)) ?? []
  }, [tokenBalances, hideSmallBalances])

  if (!tokenBalances || isLoading) {
    return <PortfolioSkeleton />
  }

  if (tokenBalances?.length === 0) {
    // TODO: consider launching moonpay here instead of just closing the drawer
    return <EmptyWalletModule type="token" onNavigateClick={toggleWalletDrawer} />
  }

  const toggleHiddenTokens = () => setShowHiddenTokens((showHiddenTokens) => !showHiddenTokens)

  return (
    <PortfolioTabWrapper>
      {visibleTokens.map(
        (tokenBalance) =>
          tokenBalance.token &&
          meetsThreshold(tokenBalance, hideSmallBalances) && (
            <TokenRow key={tokenBalance.id} {...tokenBalance} token={tokenBalance.token} />
          )
      )}
      <ExpandoRow isExpanded={showHiddenTokens} toggle={toggleHiddenTokens} numItems={hiddenTokens.length}>
        {hiddenTokens.map(
          (tokenBalance) =>
            tokenBalance.token && <TokenRow key={tokenBalance.id} {...tokenBalance} token={tokenBalance.token} />
        )}
      </ExpandoRow>
    </PortfolioTabWrapper>
  )
}

const TokenBalanceText = styled(ThemedText.BodySecondary)`
  ${EllipsisStyle}
`

export type TokenBalance = NonNullable<
  NonNullable<NonNullable<PortfolioBalancesQuery['portfolios']>[number]>['tokenBalances']
>[number]

type PortfolioToken = NonNullable<TokenBalance['token']>

function TokenRow({ token, quantity, denominatedValue, tokenProjectMarket }: TokenBalance & { token: PortfolioToken }) {
  const percentChange = tokenProjectMarket?.pricePercentChange?.value ?? 0
  const { chainId } = useWeb3React()
  const navigate = useNavigate()
  const toggleWalletDrawer = useToggleAccountDrawer()
  const navigateToTokenDetails = useCallback(async () => {
    navigate(getTokenDetailsURL(token))
    if (token) {
      const updatedToken = { ...token }
      if (chainId === SupportedChainId.XDC) {
        updatedToken.chain = Chain.Xinfin
      } else if (chainId === SupportedChainId.XDC_TESTNET) {
        updatedToken.chain = Chain.Apothem
      }
      if (token.name?.toUpperCase() === 'XDC') {
        updatedToken.address = 'NATIVE'
      }
      navigate(getTokenDetailsURL(updatedToken))
    }
    toggleWalletDrawer()
  }, [navigate, token, toggleWalletDrawer])

  const currency = gqlToCurrency(token)

  return (
    <PortfolioRow
      left={<PortfolioLogo chainId={currency.chainId} currencies={[currency]} size="40px" />}
      title={<ThemedText.SubHeader>{token?.name}</ThemedText.SubHeader>}
      descriptor={
        <TokenBalanceText>
          {token?.decimals &&
            quantity &&
            formatNumber(Number(getBalanceAmount(quantity, token?.decimals)), NumberType.TokenNonTx)}{' '}
          {token?.symbol}
        </TokenBalanceText>
      }
      onClick={navigateToTokenDetails}
      right={
        denominatedValue && (
          <>
            <ThemedText.SubHeader>
              {formatNumber(denominatedValue?.value, NumberType.PortfolioBalance)}
            </ThemedText.SubHeader>
            <Row justify="flex-end">
              <PortfolioArrow change={percentChange} size={20} strokeWidth={1.75} />
              <ThemedText.BodySecondary>{formatDelta(percentChange)}</ThemedText.BodySecondary>
            </Row>
          </>
        )
      }
    />
  )
}
