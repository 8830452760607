import { useOnClickOutside } from 'hooks/useOnClickOutside';
// import { darken } from 'polished';
import React, { ReactNode, useRef, useState } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import styled, { css, useTheme } from 'styled-components/macro';

import * as styles from './NavDropdown.css'
import { useScreenSize } from 'hooks/useScreenSize'

enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
const ExtraLinksMenuWrapper = styled.div`
  position: relative;
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${({ theme }) => (theme.darkMode === true ? theme.white : theme.black)};

  :hover,
  :focus {
    color: ${({ theme }) => (theme.darkMode === true ? theme.white : theme.black)};
  }
  :hover {
    cursor: pointer;
  }
`;

const MenuFlyout = styled.span<{ flyoutAlignment?: FlyoutAlignment, isMobile?: any }>`
  min-width: 100px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => (theme.darkMode === true ? theme.black1 : theme.white)};
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.1),
    0 16px 24px rgba(0, 0, 0, 0.1), 0 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => (theme.darkMode === true ? theme.gray700 : theme.white)};
  border-radius:  ${({ isMobile }) => isMobile ? 'auto' : '12px'};
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: ${({ isMobile }) => isMobile ? 'fixed' : 'absolute'};
  top: ${({ isMobile }) => isMobile ? 'unset' : 'calc(100% + 1rem)'};
  right: 0;
  z-index: 100;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;

  ${({ flyoutAlignment = FlyoutAlignment.RIGHT }) =>
    flyoutAlignment === FlyoutAlignment.RIGHT
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: end;
  padding: 0.5rem 0.5rem;
  justify-content: flex-start;
  color: ${({ theme }) => (theme.darkMode === true ? theme.gray300 : theme.gray500)};
  width: 100%;
  a {
    color: ${({ theme }) =>
      theme.darkMode === true ? `${theme.gray300} !important` : theme.gray500};
    width: 100%;
  }
  a:hover {
    /* background: #99a1bd14 !important; */
    opacity: 1 !important;
  }
  :hover {
    border-radius: 12px;
    cursor: pointer;
    text-decoration: none;
    background-color: ${({ theme }) =>
      theme.darkMode === true
        ? `${theme.lightGrayOverlay} !important`
        : `${theme.lightGrayOverlay} !important`};
    transition: 0.1s;
  }
`;

interface ExtraLinksMenuProps {
  items: ReactNode[];
}

const ExtraLinksMenu: React.FC<ExtraLinksMenuProps> = ({ items }) => {
  const isScreenSize = useScreenSize()
  const isMobile = !isScreenSize['md']
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const menuRef = useRef<HTMLDivElement>();
  useOnClickOutside(menuRef, isOpen ? toggleMenu : undefined);
  const theme = useTheme();

  return (
    <ExtraLinksMenuWrapper ref={menuRef as any}>
      <IconWrapper >
        <StyledMenuIcon onClick={toggleMenu}/>
      </IconWrapper>

      {isOpen && (
        <MenuFlyout theme={theme} flyoutAlignment={FlyoutAlignment.RIGHT} className={isMobile ? styles.mobileNavDropdown : ''} isMobile={isMobile}>
          {items.map((item, i) => (
            <MenuItemWrapper theme={theme} key={`extra-link-${i}`}>
              {item}
            </MenuItemWrapper>
          ))}
        </MenuFlyout>
      )}
    </ExtraLinksMenuWrapper>
  );
};

export default ExtraLinksMenu;
