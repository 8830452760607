import { Currency, CurrencyAmount, Price } from '@x-swap-protocol/sdk-core'
import BigNumber from 'bignumber.js'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'constants/locales'

interface FormatLocaleNumberArgs {
  number: CurrencyAmount<Currency> | Price<Currency, Currency> | number
  locale?: string | null
  options?: Intl.NumberFormatOptions
  sigFigs?: number
  fixedDecimals?: number
}

export default function formatLocaleNumber({
  number,
  locale,
  sigFigs,
  fixedDecimals,
  options = {},
}: FormatLocaleNumberArgs): string {
  let localeArg: string | string[]
  if (!locale || (locale && !SUPPORTED_LOCALES.includes(locale))) {
    localeArg = DEFAULT_LOCALE
  } else {
    localeArg = [locale, DEFAULT_LOCALE]
  }
  options.minimumFractionDigits = options.minimumFractionDigits || fixedDecimals
  options.maximumFractionDigits = options.maximumFractionDigits || fixedDecimals

  // Fixed decimals should override significant figures.
  options.maximumSignificantDigits = options.maximumSignificantDigits || fixedDecimals ? undefined : sigFigs

  let numberString: number
  if (typeof number === 'number') {
    numberString = fixedDecimals ? parseFloat(number.toFixed(fixedDecimals)) : number
    return numberString.toLocaleString(localeArg, options)
  } else {
    const allSigFigs = parseFloat(number.toSignificant(sigFigs)).toFixed(0).toString().length + 2
    let arrResult = number.toSignificant(allSigFigs).split('.')
    if (!arrResult[1]) {
      return new BigNumber(arrResult[0]).toFormat(0, BigNumber.ROUND_DOWN)
    }
    if (arrResult[1].length > 8) {
      arrResult[1] = arrResult[1].slice(0, 8)
    }
    let l = arrResult[1].length
    while (l > 0 && arrResult[1].charAt(l - 1) === '0') {
      arrResult[1] = arrResult[1].slice(0, l - 1)
      l--
    }
    return new BigNumber(arrResult[0]).toFormat(0, BigNumber.ROUND_DOWN) + '.' + arrResult[1]
  }
}
