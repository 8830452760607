import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { apolloClient } from 'graphql/thegraph/apollo'
import { useEthPrices } from 'hooks/useEthPrices'

export const TOKENS_PRICE = (tokens: string[]) => {
  let tokenString = `[`
  tokens.map((address) => {
    return (tokenString += `"${address?.toLowerCase()}",`)
  })
  tokenString += ']'
  const queryString = `
    query tokens {
      tokens(where: {id_in: ${tokenString}}, subgraphError: allow) {
        id
        symbol
        name
        derivedETH
      }
    }
    `
  return gql(queryString)
}

interface TokenFields {
  id: string
  symbol: string
  name: string
  derivedETH: string
}

interface TokenDataResponse {
  tokens: TokenFields[]
}

interface CurrentTokenPrice {
  // token is in some pool on uniswap
  exists: boolean

  // basic token info
  name: string
  symbol: string
  address: string
  priceUSD: number
}

/**
 * Fetch top addresses by volume
 */
export function useCurrentTokenPriceData(tokenAddresses: string[] | any): {
  loading: boolean
  error: boolean
  data:
    | {
        [address: string]: CurrentTokenPrice
      }
    | undefined
} {
  const ethPrices = useEthPrices()

  const { loading, error, data } = useQuery<TokenDataResponse>(TOKENS_PRICE(tokenAddresses), {
    client: apolloClient,
    fetchPolicy: 'no-cache',
  })

  const anyError = Boolean(error)
  const anyLoading = Boolean(loading)

  if (!ethPrices) {
    return {
      loading: true,
      error: false,
      data: undefined,
    }
  }

  // return early if not all data yet
  if (anyError || anyLoading) {
    return {
      loading: anyLoading,
      error: anyError,
      data: undefined,
    }
  }

  const parsed = data?.tokens
    ? data.tokens.reduce((accum: { [address: string]: TokenFields }, poolData) => {
        accum[poolData.id] = poolData
        return accum
      }, {})
    : {}

  // format data and calculate daily changes
  const formatted = tokenAddresses.reduce((accum: { [address: string]: CurrentTokenPrice }, address: any) => {
    const current: TokenFields | undefined = parsed[address.toLowerCase()]

    const priceUSD = current ? parseFloat(current.derivedETH) * ethPrices.current : 0

    accum[address] = {
      exists: !!current,
      address,
      name: current ? current.name : '',
      symbol: current ? current.symbol : '',
      priceUSD,
    }

    return accum
  }, {})

  return {
    loading: anyLoading,
    error: anyError,
    data: formatted,
  }
}
