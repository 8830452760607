import { Currency, CurrencyAmount, Fraction } from '@x-swap-protocol/sdk-core'
import BigNumber from 'bignumber.js'
import { DEFAULT_LOCALE, SupportedLocale } from 'constants/locales'
import JSBI from 'jsbi'
import formatLocaleNumber from 'lib/utils/formatLocaleNumber'

export function formatCurrencyAmount(
  amount: CurrencyAmount<Currency> | undefined,
  sigFigs: number,
  locale: SupportedLocale = DEFAULT_LOCALE,
  fixedDecimals?: number
): string {
  if (!amount) {
    return '-'
  }

  if (JSBI.equal(amount.quotient, JSBI.BigInt(0))) {
    return '0'
  }

  if (amount.divide(amount.decimalScale).lessThan(new Fraction(1, 100000))) {
    return `<0.00001`
  }

  return formatLocaleNumber({ number: amount, locale, sigFigs, fixedDecimals })
}

export const getBalanceAmount = (number: number, decimals: number): string => {
  const decimalMultiplier = new BigNumber(10).pow(decimals)
  return new BigNumber(number).dividedBy(decimalMultiplier).toString()
}

export const getDecimalAmount = (number: number, decimals: number): string => {
  const decimalMultiplier = new BigNumber(10).pow(decimals)
  return new BigNumber(number).times(decimalMultiplier).toString()
}
