import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'

export const xdcStakerClient = new ApolloClient({
  uri: process.env.REACT_APP_STAKER_GRAPH_PATH,
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const xdcClient = new ApolloClient({
  uri: process.env.REACT_APP_ROUTER_GRAPH_PATH,
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const apothemClient = new ApolloClient({
  uri: process.env.REACT_APP_ROUTER_GRAPH_PATH,
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// get the apollo client related to the active network for fetching blocks
export function useTheGraphClient(): ApolloClient<NormalizedCacheObject> {
  const { chainId } = useWeb3React()

  switch (chainId) {
    case SupportedChainId.XDC:
      return xdcClient
    case SupportedChainId.XDC_TESTNET:
      return apothemClient
    default:
      return xdcClient
  }
}
