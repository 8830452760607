import { createReducer } from '@reduxjs/toolkit'
import { FarmState, FetchStatus } from './interfaces'
import { fetchIncentives, fetchPools } from './actions'

export const initialState: FarmState = {
  pool: {
    status: FetchStatus.INITIAL,
    data: {},
  },
  incentive: {
    status: FetchStatus.INITIAL,
    data: {},
  },
}

export default createReducer<FarmState>(initialState, (builder) =>
  builder
    .addCase(fetchPools.pending, (state) => {
      state.pool = { ...state.pool, status: FetchStatus.PENDING }
    })
    .addCase(fetchPools.rejected, (state) => {
      state.pool = { ...state.pool, status: FetchStatus.ERROR }
    })
    .addCase(fetchPools.fulfilled, (state, { payload }) => {
      state.pool = { data: payload, status: FetchStatus.SUCCESS }
    })
    .addCase(fetchIncentives.pending, (state) => {
      state.incentive = { ...state.incentive, status: FetchStatus.PENDING }
    })
    .addCase(fetchIncentives.rejected, (state) => {
      state.incentive = { ...state.incentive, status: FetchStatus.ERROR }
    })
    .addCase(fetchIncentives.fulfilled, (state, { payload }) => {
      state.incentive = { data: payload, status: FetchStatus.SUCCESS }
    })
)
