import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'

export const ethereumBlockClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/decentraland/blocks-ethereum-goerli',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const xdcBlockClient = new ApolloClient({
  uri: process.env.REACT_APP_BLOCK_GRAPH_PATH,
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const xdcTestnetBlockClient = new ApolloClient({
  uri: process.env.REACT_APP_BLOCK_GRAPH_PATH,
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// get the apollo client related to the active network for fetching blocks
export function useBlockClient(): ApolloClient<NormalizedCacheObject> {
  const { chainId } = useWeb3React()

  switch (chainId) {
    case SupportedChainId.XDC:
      return xdcBlockClient
    case SupportedChainId.XDC_TESTNET:
      return xdcTestnetBlockClient
    default:
      return xdcBlockClient
  }
}
