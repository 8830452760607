import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
import { NATIVE_CHAIN_ID, nativeOnChain } from 'constants/tokens'
import { Chain, NftCollection, useRecentlySearchedAssetsQuery } from 'graphql/data/__generated__/types-and-hooks'
import { SearchToken } from 'graphql/data/SearchTokens'
import { CHAIN_NAME_TO_CHAIN_ID } from 'graphql/data/util'
import { useFetchedTokenDatas } from 'graphql/thegraph/tokens/tokenData'
import useSearchTokenFromSubGraph from 'hooks/useSearchTokensFromSubgraph'
import { useAtom } from 'jotai'
import { atomWithStorage, useAtomValue } from 'jotai/utils'
import { GenieCollection } from 'nft/types'
import { useCallback, useMemo } from 'react'
import { getNativeTokenDBAddress } from 'utils/nativeTokens'

type RecentlySearchedAsset = {
  isNft?: boolean
  address: string
  chain: Chain
}

// Temporary measure used until backend supports addressing by "NATIVE"
const NATIVE_QUERY_ADDRESS_INPUT = null as unknown as string
function getQueryAddress(chain: Chain) {
  return getNativeTokenDBAddress(chain) ?? NATIVE_QUERY_ADDRESS_INPUT
}

const recentlySearchedAssetsAtom = atomWithStorage<RecentlySearchedAsset[]>('recentlySearchedAssets', [])

export function useAddRecentlySearchedAsset() {
  const [searchHistory, updateSearchHistory] = useAtom(recentlySearchedAssetsAtom)

  return useCallback(
    (asset: RecentlySearchedAsset) => {
      // Removes the new asset if it was already in the array
      const newHistory = searchHistory.filter(
        (oldAsset) => !(oldAsset.address === asset.address && oldAsset.chain === asset.chain)
      )
      newHistory.unshift(asset)
      updateSearchHistory(newHistory)
    },
    [searchHistory, updateSearchHistory]
  )
}

export function useRecentlySearchedAssets() {
  const history = useAtomValue(recentlySearchedAssetsAtom)
  const shortenedHistory = useMemo(() => history.slice(0, 4), [history])
  const { chainId } = useWeb3React();

  const queryData = useMemo(() => {
    return shortenedHistory
      .filter((asset) => !asset.isNft)
      .map((token) => {
        return {
          address: token.address === NATIVE_CHAIN_ID ? getQueryAddress(token.chain) : token.address,
          chain: token.chain,
        };
      });
  }, [shortenedHistory]);

  const arrAddress = queryData?.map((item) => item.address);

    const { data: tokenDatas } = useFetchedTokenDatas(arrAddress || []);

    const convertData = useSearchTokenFromSubGraph(Object.values(tokenDatas || []), chainId);

  const data = useMemo(() => {
    if (shortenedHistory.length === 0) return [];
    else if (!convertData) return undefined;
    // Collects both tokens and collections in a map, so they can later be returned in original order
    const resultsMap: { [key: string]: GenieCollection | SearchToken } = {};

    convertData?.filter(Boolean).forEach((token) => {
      resultsMap[token.address ?? `NATIVE-${token.chain}`] = token;
    });

    const data: (SearchToken | GenieCollection)[] = [];
    shortenedHistory.forEach((asset) => {
      if (asset.address === 'NATIVE') {
        // Handles special case where wMATIC data needs to be used for MATIC
        const native = nativeOnChain(CHAIN_NAME_TO_CHAIN_ID[asset.chain] ?? SupportedChainId.XDC);
        const queryAddress = getQueryAddress(asset.chain)?.toLowerCase() ?? `NATIVE-${asset.chain}`;
        const result = resultsMap[queryAddress];
        if (result) data.push({ ...result, address: 'NATIVE', ...native });
      } else {
        const result = resultsMap[asset.address];
        if (result) data.push(result);
      }
    });
    return data;
  }, [tokenDatas, shortenedHistory]);

  return { data }
}
