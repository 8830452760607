import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isAddress } from 'ethers/lib/utils'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { notEmpty } from 'utils'

import { AppState } from '../types'
import { addTokenKeys, updateTokenData, updateTokenLoading } from './actions'
import { TokenData } from './types'
// format dayjs with the libraries that we need
dayjs.extend(utc)

export function useTokensLoading(): [boolean, (loading: boolean) => void] {
  const dispatch = useAppDispatch()

  const tokenLoading = useAppSelector((state: AppState) => state.tokens.loading)
  const loadingDispatch = useCallback(
    (loading: boolean) => {
      dispatch(updateTokenLoading({ loading }))
    },
    [dispatch]
  )

  return [tokenLoading, loadingDispatch]
}

export function useAllTokenData(): {
  [address: string]: { data: TokenData | undefined; lastUpdated: number | undefined }
} {
  const { chainId } = useWeb3React()
  return useAppSelector((state: AppState) => state.tokens.byAddress[chainId as SupportedChainId] ?? {})
}

export function useUpdateTokenData(): (tokens: TokenData[]) => void {
  const dispatch = useAppDispatch()
  const { chainId } = useWeb3React()

  return useCallback(
    (tokens: TokenData[]) => {
      if (chainId) {
        dispatch(updateTokenData({ tokens, networkId: chainId as SupportedChainId }))
      }
    },
    [chainId, dispatch]
  )
}

export function useAddTokenKeys(): (addresses: string[]) => void {
  const dispatch = useAppDispatch()
  const { chainId } = useWeb3React()
  return useCallback(
    (tokenAddresses: string[]) => {
      if (chainId) dispatch(addTokenKeys({ tokenAddresses, networkId: chainId as SupportedChainId }))
    },
    [chainId, dispatch]
  )
}

export function useTokenDatas(addresses: string[] | undefined): TokenData[] | undefined {
  const allTokenData = useAllTokenData()
  const addTokenKeys = useAddTokenKeys()

  // if token not tracked yet track it
  addresses?.map((a) => {
    if (!allTokenData[a]) {
      addTokenKeys([a])
    }
  })

  const data = useMemo(() => {
    if (!addresses) {
      return undefined
    }
    return addresses
      .map((a) => {
        return allTokenData[a]?.data
      })
      .filter(notEmpty)
  }, [addresses, allTokenData])

  return data
}

export function useTokenData(address: string | undefined): TokenData | undefined {
  const allTokenData = useAllTokenData()
  const addTokenKeys = useAddTokenKeys()

  // if invalid address return
  if (!address || !isAddress(address)) {
    return undefined
  }

  // if token not tracked yet track it
  if (!allTokenData[address]) {
    addTokenKeys([address])
  }

  // return data
  return allTokenData[address]?.data
}

/**
 * Get top pools addresses that token is included in
 * If not loaded, fetch and store
 * @param address
 */
// export function useTokenChartData(address: string): TokenChartEntry[] | undefined {
//   const dispatch = useAppDispatch()
//   const {chainId} = useWeb3React()
//   const token = useAppSelector((state: AppState) => state.tokens.byAddress[chainId]?.[address])
//   const chartData = token.chartData
//   const [error, setError] = useState(false)
//   const { dataClient } = useClients()

//   useEffect(() => {
//     async function fetch() {
//       const { error, data } = await fetchTokenChartData(address, dataClient)
//       if (!error && data) {
//         dispatch(updateChartData({ tokenAddress: address, chartData: data, networkId: chainId }))
//       }
//       if (error) {
//         setError(error)
//       }
//     }
//     if (!chartData && !error) {
//       fetch()
//     }
//   }, [address, dispatch, error, chartData, dataClient, chainId])

//   // return data
//   return chartData
// }

/**
 * Get top pools addresses that token is included in
 * If not loaded, fetch and store
 * @param address
 */
// export function useTokenPriceData(
//   address: string,
//   interval: number,
//   timeWindow: OpUnitType
// ): PriceChartEntry[] | undefined {
//   const dispatch = useAppDispatch()
//   const {chainId} = useWeb3React()
//   const token = useAppSelector((state: AppState) => state.tokens.byAddress[chainId]?.[address])
//   const priceData = token.priceData[interval]
//   const [error, setError] = useState(false)
//   const { dataClient, blockClient } = useClients()

//   // construct timestamps and check if we need to fetch more data
//   const oldestTimestampFetched = token.priceData.oldestFetchedTimestamp
//   const utcCurrentTime = dayjs()
//   const startTimestamp = utcCurrentTime.subtract(1, timeWindow).startOf('hour').unix()

//   useEffect(() => {
//     async function fetch() {
//       const { data, error: fetchingError } = await fetchTokenPriceData(
//         address,
//         interval,
//         startTimestamp,
//         dataClient,
//         blockClient
//       )
//       if (data) {
//         dispatch(
//           updatePriceData({
//             tokenAddress: address,
//             secondsInterval: interval,
//             priceData: data,
//             oldestFetchedTimestamp: startTimestamp,
//             networkId: chainId,
//           })
//         )
//       }
//       if (fetchingError) {
//         setError(true)
//       }
//     }

//     if (!priceData && !error) {
//       fetch()
//     }
//   }, [
//     chainId,
//     address,
//     blockClient,
//     dataClient,
//     dispatch,
//     error,
//     interval,
//     oldestTimestampFetched,
//     priceData,
//     startTimestamp,
//     timeWindow,
//   ])

//   // return data
//   return priceData
// }
