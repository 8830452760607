import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { SupportedChainId } from 'constants/chains'

import store from '../../state/index'

const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.GOERLI]: '',

  [SupportedChainId.MAINNET]: '', // XSwap

  [SupportedChainId.ARBITRUM_ONE]: '',

  [SupportedChainId.OPTIMISM]: '',

  [SupportedChainId.POLYGON]: '',

  [SupportedChainId.CELO]: '',

  [SupportedChainId.BNB]: '',

  [SupportedChainId.XDC]: process.env.REACT_APP_ROUTER_GRAPH_PATH ?? '',

  [SupportedChainId.XDC_TESTNET]: '',
}

const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[SupportedChainId.XDC] })
const httpStakerLink = new HttpLink({ uri: process.env.REACT_APP_STAKER_GRAPH_PATH ?? '' })
// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId = store.getState().application.chainId

  operation.setContext(() => ({
    uri:
      chainId && CHAIN_SUBGRAPH_URL[chainId] ? CHAIN_SUBGRAPH_URL[chainId] : CHAIN_SUBGRAPH_URL[SupportedChainId.XDC],
  }))

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

export const apolloStakerClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpStakerLink,
})
