import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useAccountDrawer } from 'components/AccountDrawer'
import ExtraLinksMenu from 'components/NavBar/ExtraLinksMenu'
import Web3Status from 'components/Web3Status'
import { useMGTMMicrositeEnabled } from 'featureFlags/flags/mgtm'
import { chainIdToBackendName } from 'graphql/data/util'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { useProfilePageState } from 'nft/hooks'
import { ProfilePageStateType } from 'nft/types'
import { ReactNode, useCallback } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { ExternalLink } from 'theme'
import { darkTheme, lightTheme } from 'theme/colors'

import { UniIcon } from '../../nft/components/icons'
import { useIsNavSearchInputVisible } from '../../nft/hooks/useIsNavSearchInputVisible'
import { Bag } from './Bag'
import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
import { MenuDropdown } from './MenuDropdown'
import { SearchBar } from './SearchBar'
import * as styles from './style.css'
import { useIsFarmsPage } from '../../hooks/useIsFarmsPage'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`
const Logo = styled.div`
  border-radius: 50%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 48px;
  transition: transform 0.3s ease 0s;
  &:hover {
    transform: rotate(-180deg) scale(1.1);
  }
  @media (max-width: 767px) {
    width: 40px;
    height: auto;
  }
  img {
    width: 100%;
    height: auto;
  }
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

type HeaderFrameType = {
  darkMode?: boolean
}

const HeaderFrame = styled.div<HeaderFrameType>`
  border-radius: 14px;
  padding: 0px 6px;
  display: flex;
`

const WrapperMenuItem = styled.div`
  white-space: nowrap;
  display: flex;
  @media (max-width: 767px) {
    display: flex;
    flex: 1;
    & a {
      justify-content: center;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`

const StyledNavLink = styled(NavLink)<{ isActive?: boolean | any }>`
  align-items: flex-start;
  min-width: fit-content;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => (theme.darkMode === true ? darkTheme.gray300 : lightTheme.gray500)};
  font-size: 1rem;
  font-weight: ${({ isActive }) => (isActive === true ? 600 : 500)};
  padding: 6px 8px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
`

const WrapperFarmAndStake = styled.a<{ theme: any }>`
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  &:hover {
    transition: 0.5s;
    text-decoration: underline;
  }
`

export const PageTabs = ({ darkMode }: HeaderFrameType) => {
  const { pathname } = useLocation()
  const { chainId: connectedChainId } = useWeb3React()
  const chainName = chainIdToBackendName(connectedChainId)
  const infoPage = process.env.REACT_APP_INFO_PAGE || '#'

  const isPoolActive = useIsPoolsPage()
  const isFarmActive = useIsFarmsPage()
  const micrositeEnabled = useMGTMMicrositeEnabled()
  const theme = useTheme()
  // const navigate = useNavigate();

  // const shouldDisableNFTRoutes = useAtomValue(shouldDisableNFTRoutesAtom)

  return (
    <>
      <WrapperMenuItem>
        <MenuItem href="/swap" isActive={pathname.startsWith('/swap')}>
          <Trans>Swap</Trans>
        </MenuItem>
        <MenuItem href="/tokens" isActive={pathname.startsWith('/tokens')}>
          <Trans>Tokens</Trans>
        </MenuItem>
        {/* {!shouldDisableNFTRoutes && (
          <MenuItem dataTestId="nft-nav" href="/nfts" isActive={isNftPage}>
            <Trans>NFTs</Trans>
          </MenuItem>
        )} */}
        <MenuItem href="/pools" dataTestId="pool-nav-link" isActive={isPoolActive}>
          <Trans>Pools</Trans>
        </MenuItem>
        <a href={infoPage} target="_blank" rel="noopener noreferrer" className={styles.menuItem}>
          <Trans>Charts</Trans>
        </a>
        <NavLink
          className={styles.menuItem}
          to="farm"
          style={{
            padding: '8px 8px',
            fontWeight: 500,
            textDecoration: 'none',
            border: '1px solid #ff222255',
          }}
        >
          <div style={{ color: isFarmActive ? theme.textPrimary : 'inherit' }}>
            <Trans>Farm V3</Trans>
          </div>
        </NavLink>
      </WrapperMenuItem>

      <ExtraLinksMenu
        items={[
          <ExternalLink
            style={{ padding: '8px 8px' }}
            href="https://v2.xspswap.finance"
            key="v2-xspswap"
            id="v2-xspswap"
            target="_blank"
          >
            <Trans>V2 Swap</Trans>
          </ExternalLink>,
          <ExternalLink
            style={{ padding: '8px 8px' }}
            href="https://analytics.xspswap.finance"
            key="v2-charts"
            id="v2-charts"
            target="_blank"
          >
            <Trans>V2 Charts</Trans>
          </ExternalLink>,
          <ExternalLink
            style={{ padding: '8px 8px' }}
            href="https://v2.xspswap.finance/xsp-staking"
            key="xsp-staking-nav-link"
            id="xsp-staking-nav-link"
            target="_blank"
          >
            <Trans>XSP Staking</Trans>
          </ExternalLink>,
          <ExternalLink
            style={{ padding: '8px 8px' }}
            href="https://xdc.sale"
            id="launchpad-nav-link"
            key="launchpad-nav-link"
            target="_blank"
          >
            <Trans>Launchpad</Trans>
          </ExternalLink>,
          <ExternalLink
            style={{ padding: '8px 8px' }}
            href="https://gov.xspswap.finance"
            id="vote-nav-link"
            key="vote-nav-link"
            target="_blank"
          >
            <Trans>Vote</Trans>
          </ExternalLink>,
          <ExternalLink
            style={{ padding: '8px 8px' }}
            href="https://xdc.farm/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>Farm V2</Trans>
          </ExternalLink>,
        ]}
      />
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const isNavSearchInputVisible = useIsNavSearchInputVisible()
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])
  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <UniIcon size="48" className={styles.logo} onClick={handleUniIconClick} />
            </Box>
            {!isNftPage && (
              <Box display={{ sm: 'flex', lg: 'none' }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}
            <Row display={{ sm: 'none', lg: 'flex' }}>
              <PageTabs />
            </Row>
          </Box>
          <Box
            className={styles.searchContainer}
            {...(isNavSearchInputVisible && {
              display: 'flex',
            })}
          >
            <SearchBar />
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              <Box position="relative" display={isNavSearchInputVisible ? 'none' : { sm: 'flex' }}>
                <SearchBar />
              </Box>
              {isNftPage && sellPageState !== ProfilePageStateType.LISTING && <Bag />}
              {!isNftPage && (
                <Box display={{ sm: 'none', lg: 'flex' }}>
                  <ChainSelector />
                </Box>
              )}
              <Web3Status />
              <MenuDropdown />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
