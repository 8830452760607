import { SearchToken } from 'graphql/data/SearchTokens';
import { TokenData } from 'state/tokens/types';
import { chainIdToBackendName } from 'graphql/data/util';

const useSearchTokenFromSubGraph = (data: TokenData[], chainId: number | undefined): SearchToken[] => {
  const chainName = chainIdToBackendName(chainId);
  const resultData: SearchToken[] = [];
  data.map((token) => {
    resultData.push({
      id: token.address,
      // decimals: 18,
      name: token.name,
      chain: chainName,
      standard: undefined,
      address: token.address,
      symbol: token.symbol,
      market: {
        id: token.address,
        price: {
          id: token.address,
          value: token.priceUSD,
          currency: undefined,
        },
        pricePercentChange: {
          id: token.address,
          value: token.priceUSDChange,
        },
        volume24H: {
          id: token.address,
          value: token.volumeUSDChange,
          currency: undefined,
        },
      },
      project: {
        id: token.address,
        logoUrl: undefined,
        safetyLevel: undefined,
      },
    });
  });
  return resultData;
};

export default useSearchTokenFromSubGraph;
