import gql from 'graphql-tag';
import { apolloClient } from 'graphql/thegraph/apollo';
import { useTrendingTokensQuery } from 'graphql/thegraph/__generated__/types-and-hooks';
import { useMemo } from 'react';

gql`
  query trendingTokens {
    tokens(first: 5, orderBy: volume, orderDirection: desc, skip: 1) {
      id
    }
  }
`;

export default function useTrendingTokens() {
  const { data, loading } = useTrendingTokensQuery({
    fetchPolicy: 'network-only',
    client: apolloClient,
  });
  
  return useMemo(
    () => ({ data: data?.tokens?.map((token) =>  token), loading }),
    [data?.tokens, loading]
  );
}


